import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div className="prose mx-auto text-prose-xl">
      <h1>{`Apple Blossom`}</h1>
      <p>{`Painting for the Washington State Apple Blossom Festival led to a series of sketches painted on site in early May 2021. Being in the orchards among the trees a blaze with blossoms, was an immersive education in what it takes to produce an apple. This work encapsulates the orchard distinctly set in the shadow of Mission Ridge. It’s the story of a tree, a single branch and pruning scars set against the blue spring sky. Alight with blossoms and the buzzing of bees.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/af09abac640b50415d0e22efb14ce18c/62976/IMG_3425.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "75.99999999999999%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIDBP/EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABZlizQzif/8QAGRABAAMBAQAAAAAAAAAAAAAAAQACESEi/9oACAEBAAEFAiN426OSz6sa/wD/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPwGI/8QAFhEBAQEAAAAAAAAAAAAAAAAAARAR/9oACAECAQE/ARyf/8QAFxAAAwEAAAAAAAAAAAAAAAAAACAhMf/aAAgBAQAGPwKmL//EABsQAQACAgMAAAAAAAAAAAAAAAEAESFRMWGB/9oACAEBAAE/IVp5KHmVL4E2mIDYV61MotM//9oADAMBAAIAAwAAABAoH//EABcRAAMBAAAAAAAAAAAAAAAAAAABETH/2gAIAQMBAT8Ql6Sf/8QAGBEAAgMAAAAAAAAAAAAAAAAAAAERIVH/2gAIAQIBAT8QalEvT//EABwQAQACAwADAAAAAAAAAAAAAAEAESExQVFhgf/aAAgBAQABPxC8SxdIubLzXH5DRw+4gsDR3UBJ0c9QufBmi5//2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Apple Blossom",
              "title": "Apple Blossom",
              "src": "/static/af09abac640b50415d0e22efb14ce18c/4b190/IMG_3425.jpg",
              "srcSet": ["/static/af09abac640b50415d0e22efb14ce18c/e07e9/IMG_3425.jpg 200w", "/static/af09abac640b50415d0e22efb14ce18c/066f9/IMG_3425.jpg 400w", "/static/af09abac640b50415d0e22efb14ce18c/4b190/IMG_3425.jpg 800w", "/static/af09abac640b50415d0e22efb14ce18c/e5166/IMG_3425.jpg 1200w", "/static/af09abac640b50415d0e22efb14ce18c/b17f8/IMG_3425.jpg 1600w", "/static/af09abac640b50415d0e22efb14ce18c/62976/IMG_3425.jpg 3907w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p>{`16 x 20”. Canvas prints available through the Washington State Apple Blossom Festival.
Price on request.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/589b65f9954381df78e63ca682a99c6d/81043/IMG_3332.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "98%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAECAwT/xAAWAQEBAQAAAAAAAAAAAAAAAAABAgD/2gAMAwEAAhADEAAAAd647wnCC8mqwK//xAAaEAADAQADAAAAAAAAAAAAAAAAAQIREBMh/9oACAEBAAEFAtHqO+USX4nmzT5//8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFREBAQAAAAAAAAAAAAAAAAAAASD/2gAIAQIBAT8BCP/EABsQAAIDAAMAAAAAAAAAAAAAAAABEBEhEjFB/9oACAEBAAY/AjyjpsXJm6puP//EABwQAQACAgMBAAAAAAAAAAAAAAEAESFRMUFxgf/aAAgBAQABPyFF0YNxPoeuZwvkiwgpfJajIyrzNyvJaNS0MlXcwdE//9oADAMBAAIAAwAAABAnKAD/xAAYEQADAQEAAAAAAAAAAAAAAAAAAREhMf/aAAgBAwEBPxDj0lHrIf/EABcRAQADAAAAAAAAAAAAAAAAAAEAECH/2gAIAQIBAT8QIJl//8QAHRABAQACAgMBAAAAAAAAAAAAAREAMSFBYXGBwf/aAAgBAQABPxAcBBVZTigIrgLr34w4CDtH7ja4Cox5ExCotFq1Bm58wh6Hq5hA4QJHIC3RVwhLezP/2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Apple Blossom",
              "title": "Apple Blossom",
              "src": "/static/589b65f9954381df78e63ca682a99c6d/4b190/IMG_3332.jpg",
              "srcSet": ["/static/589b65f9954381df78e63ca682a99c6d/e07e9/IMG_3332.jpg 200w", "/static/589b65f9954381df78e63ca682a99c6d/066f9/IMG_3332.jpg 400w", "/static/589b65f9954381df78e63ca682a99c6d/4b190/IMG_3332.jpg 800w", "/static/589b65f9954381df78e63ca682a99c6d/e5166/IMG_3332.jpg 1200w", "/static/589b65f9954381df78e63ca682a99c6d/b17f8/IMG_3332.jpg 1600w", "/static/589b65f9954381df78e63ca682a99c6d/81043/IMG_3332.jpg 2912w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p>{`48”x4” in, oil on canvas.
Price on request.`}</p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      